import React from "react";
import {
  AnnotationIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import RequirementsSection from "../components/sections/RequirementsSection";
import Footer from "../components/sections/Footer";
import InfoSection from "../components/sections/InfoSection";
import RequirementItem from "../components/elements/RequirementItem";

const RequirementsEnPage = () => (
  <div className="bg-white niramit-regular">
    <PageTitle pageTitle={"AxxPay requirements"} description={``} />
    <Header lang="en" />
    <RequirementsSection
      title="Terminal app Android OS Requirements"
      description="The device the app will be deployed on must support the following as a minimum and keep up with MPP updates:"
    >
      <RequirementItem
        title=""
        description=""
        imgName="axxpay-image.png"
        features={[
          {
            name: "Android OS version from 8",
            description:
              " This minimum requirement must be maintained as security standards are updated. It will be informed when this version needs to be upgraded",
            icon: GlobeAltIcon,
          },
          {
            name: "Manufacturer",
            description:
              "The manufacturer is listed as a Google Play Protect partner: https://www.android.com/certified/partners/#tab-panel-brands",
            icon: ScaleIcon,
          },
          {
            name: "The following sources are accepted for defining supported devices:",
            description:
              "https://security.samsungmobile.com/workScope.smsb  https://www.android.com/one/ https://lgsecurity.lge.com/security_updates.html",
            icon: AnnotationIcon,
          },
          {
            name: "SafetyNet checks",
            description:
              "The device passes SafetyNet checks: https://developer.android.com/training/safetynet",
            icon: AnnotationIcon,
          },          
        ]}
      />
      <RequirementItem
        title=""
        description=""
        reverse={true}
        imgName="axxpay_image_2.png"
        features={[
          {
            name: "Location Services enabled",
            description: "",
            icon: AnnotationIcon,
          },
          {
            name: "The device is receiving security updates at least twice a year",
            description: "",
            icon: AnnotationIcon,
          },
          {
            name: "Access to Phone Services & File System",
            description: "",
            icon: AnnotationIcon,
          },
          {
            name: "The device has a hardware backed keystore",
            description: "",
            icon: LightningBoltIcon,
          },                  
        ]}
      />
    </RequirementsSection>
    <InfoSection
      title="Unsupported Devices"
      imgName=""
      text={[
        "A device will not be supported if it is no longer receiving security updates from the manufacturer or other relevant party, and there is a vulnerability that compromises the security of the mPOS application that cannot be mitigated without a patch.",
        "Depending on the issue, there will be a grace period** during which new devices of the same make and model will not be permitted, but existing devices can be phased out over a period of maximum three months.",
        "** The length of the grace period depends on the issue and is decided on a per case basis",
      ]}
    />
    <Footer lang="en" />
  </div>
);

export default RequirementsEnPage;
