import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { useImageForData } from "../../hooks/useAllImages";

const RequirementItem = ({
  title,
  description,
  imgName,
  features,
  reverse,
}) => (
  <div className="relative mt-12 sm:mt-16 lg:mt-24">
    <div
      className={`lg:flex ${
        reverse ? "lg:flex-row-reverse" : " "
      } lg:gap-8 `}
    >
      <div className="relative lg:w-2/3">
        {title && (
          <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            {title}
          </h3>
        )}
        {description && (
          <p className="mt-3 text-lg text-gray-500">{description}</p>
        )}

        <dl className={"space-y-10 " + (title || description ? "mt-10" : "")}>
          {features.map((item) => (
            <div key={item.name} className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-amber-500 text-white">
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                  {item.name}
                </p>
              </dt>
              {item.description && (
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {item.description}
                </dd>
              )}
            </div>
          ))}
        </dl>
      </div>

      <div className="mt-12 relative lg:mt-0 lg:w-1/2" aria-hidden="true">
        <svg
          className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
        >
          <defs>
            <pattern
              id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={784}
            height={404}
            fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
          />
        </svg>
        <GatsbyImage
          image={useImageForData(imgName)}
          alt={title}
          className="relative mx-auto rounded-lg shadow-lg object-cover object-center w-full h-full"
        />
      </div>
    </div>
  </div>
);

export default RequirementItem;
