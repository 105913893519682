import React from "react";

const InfoSection = ({ title, text }) => (
  <div className="bg-amber-500">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 lg:flex lg:justify-between">
      <div>
        <h2 className="text-3xl font-semibold text-white sm:text-4xl sm:tracking-tight">
          {title}
        </h2>
        <div className="mt-4 grid gap-4 text-xl auto-rows-min text-white mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          {text.map((par) => (
            <p>{par}</p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default InfoSection;
